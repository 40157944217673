




















































































































































import { debounceProcess } from "@/helpers/debounce";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Mode } from "@/models/enums/global.enum";
import { Messages } from "@/models/enums/messages.enum";
import { ResponseListContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataWarehouseLocation,
  ResponseWarehouseLocation,
} from "@/models/interface/logistic.interface";
import { assetsServices } from "@/services/assets.service";
import { contactServices } from "@/services/contact.service";
import { logisticServices } from "@/services/logistic.service";
import { AssetAdjustmentFormDto } from "@interface/asset";
import moment from "moment";
import Vue from "vue";

export default Vue.extend({
  name: "AdjustmentAssignment",
  data() {
    this.getListContact = debounceProcess(this.getListContact, 200);
    this.getListAssetBook = debounceProcess(this.getListAssetBook, 200);
    this.getParentUnitCode = debounceProcess(this.getParentUnitCode, 200);
    return {
      DEFAULT_DATE_FORMAT,
      mode: "" as string,
      idReferenceNumber: "" as string,
      loadingListAssetBook: false as boolean,
      disabledSave: false as boolean,
      dataListWarehouseLocation: {
        data: [] as DataWarehouseLocation[],
        totalElements: 0,
        totalPages: 0,
      } as ResponseWarehouseLocation,
      dataSourceEmployee: [] as any[],
      depreciateCheckBox: true as boolean,
      form: this.$form.createForm(this, { name: "adjustmentAssignment" }),
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      loadingEmployee: false as boolean,
      loadingLocation: false as boolean,
      isFormSubmitted: false as boolean,
      loadingParentUnitCode: false as boolean,
      dataParentUnitCode: {
        data: [],
        totalElements: 0,
        totalPages: 0,
      } as any,
      formRules: {
        assetNumber: {
          label: "lbl_asset_number",
          name: "assetNumber",
          placeholder: "lbl_asset_number",
          decorator: [
            "assetNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        book: {
          label: "lbl_book",
          name: "book",
          placeholder: "lbl_book",
          decorator: [
            "book",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        unit: {
          label: "lbl_qty",
          name: "unit",
          placeholder: "lbl_qty",
          decorator: [
            "unit",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        location: {
          label: "lbl_location",
          name: "location",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "location",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        customerLocation: {
          label: "lbl_customer_location",
          name: "customerLocation",
          placeholder: "lbl_customer_location_placeholder",
          decorator: [
            "customerLocation",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        transferDate: {
          label: "lbl_transfer_date",
          name: "transferDate",
          placeholder: "lbl_transfer_date",
          decorator: [
            "transferDate",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        rentLocation: {
          label: "lbl_rent_location",
          name: "rentLocation",
          placeholder: "lbl_rent_location",
          decorator: [
            "rentLocation",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        employee: {
          label: "lbl_employee",
          name: "employee",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "employee",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        nikemployee: {
          label: "lbl_nik_employee",
          name: "nikemployee",
          placeholder: "lbl_nik_employee",
          decorator: [
            "nikemployee",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        serialnumber: {
          label: "lbl_serial_number",
          name: "serialnumber",
          placeholder: "lbl_serial_number_placeholder",
          decorator: [
            "serialnumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        unitcode: {
          label: "lbl_unit_code",
          name: "unitcode",
          placeholder: "lbl_unit_code_placeholder",
          decorator: [
            "unitcode",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        unitcodeparent: {
          label: "lbl_unit_code_parent",
          name: "unitcodeparent",
          placeholder: "lbl_unit_code_parent_placeholder",
          decorator: [
            "unitcodeparent",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        depreciate: {
          label: "lbl_depreciate",
          name: "depreciate",
          placeholder: "lbl_depreciate",
        },
      },
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "adjustmentAssignment" });
  },
  created() {
    this.mode = this.$route.meta.mode;
  },
  mounted() {
    switch (this.mode) {
      case Mode.EDIT:
        this.getListContact("");
        this.getListOfWarehouseLocation("");
        this.getListAssetBook("");
        this.getParentUnitCode("");
        this.getDetailData();
        break;
      case Mode.VIEW:
        this.idReferenceNumber = decodeURIComponent(this.$route.params.id);
        this.getDataViewAssignment();
        break;
      default:
        break;
    }
  },
  methods: {
    moment,
    getDataViewAssignment() {
      let params = {
        page: 0,
        limit: 10,
        search: `referenceNo~${this.idReferenceNumber}`,
      } as RequestQueryParamsModel;
      assetsServices.detailAssetAdjustment(params).then(data => {
        if (!data.length) return;
        let datapush = [] as any;
        datapush.push({
          unitCode: data[0].assignment.parentUnitCode,
        });
        this.dataParentUnitCode = {
          data: datapush,
        };
        this.form.setFieldsValue({
          assetNumber: data[0].assignment.assetNumber,
          unit: data[0].assignment.unit,
          location: data[0].assignment.assetLocation?.id
            ? data[0].assignment.assetLocation?.id
            : null,
          customerLocation: data[0].assignment.customerLocation,
          employee: data[0].assignment.employee?.id
            ? data[0].assignment.employee?.id
            : null,
          nikemployee: data[0].assignment.employee?.employeeData.nik
            ? data[0].assignment.employee?.employeeData.nik
            : null,
          serialnumber: data[0].assignment.serialNumber,
          unitcode: data[0].assignment.unitCode,
          unitcodeparent: data[0].assignment.parentUnitCode,
        });
      });
    },
    getParentUnitCode(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;

      if (valueSearch) params.search = `unitCode~*${valueSearch}*`;
      this.loadingParentUnitCode = true;
      assetsServices
        .listMasterAsset(params)
        .then(data => {
          this.dataParentUnitCode = data;
        })
        .finally(() => (this.loadingParentUnitCode = false));
    },
    cancelHandle(): void {
      this.form.resetFields();
      this.$router.push("/inquiry/find");
    },
    getListAssetBook(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
        search: `type~commercial_AND_active~true`,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search += `_AND_name~*${valueSearch}*`;
      this.loadingListAssetBook = true;
      assetsServices
        .listAssetBook(params)
        .then(data => {
          this.dataListBook = data.data;
        })
        .finally(() => (this.loadingListAssetBook = false));
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    getListOfWarehouseLocation(valueSearch) {
      let params = {
        limit: this.limit,
        page: 0,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search = `name~*${valueSearch}*`;
      if (this.direction) params.sorts = this.sort;
      this.loadingLocation = true;
      logisticServices
        .listWarehouseLocation(params, "")
        .then(res => {
          this.dataListWarehouseLocation = res;
        })
        .finally(() => (this.loadingLocation = false));
    },
    getListContact(valueSearch) {
      let params = {
        limit: 10,
        page: 0,
        search: `employee~true`,
      } as RequestQueryParamsModel;
      if (valueSearch)
        params.search += `_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*_OR_phoneNumber~*${valueSearch}*_OR_email~*${valueSearch}*`;
      if (this.direction) params.sorts = this.sort;
      this.dataSourceEmployee = [];
      this.loadingEmployee = true;
      contactServices
        .listContactData(params)
        .then((res: ResponseListContactData) => {
          this.dataSourceEmployee = res.data;
          this.totalElements = res.totalElements;
        })
        .finally(() => (this.loadingEmployee = false));
    },
    getDetailData(): void {
      assetsServices
        .listAssetAdjustmentFormById({}, this.$route.params.id)
        .then((data: AssetAdjustmentFormDto) => {
          if (data.assetStatus == "Retired") {
            this.idReferenceNumber = "found";
            this.disabledSave = true;
          } else {
            this.idReferenceNumber = "";
            this.disabledSave = false;
          }
          this.form.setFieldsValue({
            assetNumber: data.assetNo,
            unit: data.quantity,
            location: data.assignment.locationId,
            customerLocation: data.assignment.customerLocation,
            employee: data.assignment.employeeId,
            nikemployee: data.assignment.employeeNik,
            serialnumber: data.assignment.serialNumber,
            unitcode: data.assignment.unitCode,
            unitcodeparent: data.assignment.parentUnitCode,
            transferDate: this.moment(new Date()).format(),
          });
          this.handleOptLocation(data);
          const checkExistUnitCodeParent = this.dataParentUnitCode[
            "data"
          ].findIndex(
            item => item.assignment.unitCode === data.assignment.unitCode
          );
          if (checkExistUnitCodeParent == -1)
            this.dataParentUnitCode["data"].push({
              unitCode: data.assignment.unitCode,
            });
        })
        .finally();
    },
    handleOptLocation(detail): void {
      if (
        this.dataListWarehouseLocation.data.findIndex(
          item => item.id === detail.assignment.locationId
        ) !== -1
      ) {
        return;
      }
      this.dataListWarehouseLocation.data.push({
        id: detail.assignment.locationId,
        name: detail.assignment.location.split(",")[0],
        warehouse: {
          name: detail.assignment.location.split(",")[1],
          branchWarehouse: {
            name: detail.assignment.location.split(",")[2],
          } as any,
        } as any,
      } as any);
    },
    submitForm(e: Event): void {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const dataPost = {
            location: values.location,
            employeeId: values.employee,
            transferDate: this.moment(values.transferDate).format(),
            serialNumber: values.serialnumber,
            unitCode: values.unitcode,
            parentUnitCode: "",
          };

          this.isFormSubmitted = true;
          assetsServices
            .updateAssetAdjustmentAssignment(dataPost, this.$route.params.id)
            .then(() => {
              this.form.resetFields();
              this.$notification.success({
                description: Messages.CREATE_SUCCESS,
                message: "Success",
                duration: 30,
              });

              this.$router.push("/inquiry/find");
            })
            .catch(error => {
              this.$notification.error({
                description: Messages.CREATE_FAIL,
                message: "Error",
                duration: 30,
              });
              this.$notification.error({
                description: error.details,
                message: "Error",
                duration: 30,
              });

              this.isFormSubmitted = false;
            })
            .finally(() => (this.isFormSubmitted = false));
        } else {
          this.$notification["error"]({
            message: "Error",
            description: "Form is mandatory",
          });
        }
      });
    },
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 4 },
        wrapperCol: { span: 6 },
      };
    },
  },
});
